import { Component, OnInit } from "@angular/core";
import { userTypesEnum } from "src/app/common/constants/common.constants";
import { Enterprise, Holiday } from "src/app/common/models/admin.class";
import { AuthGuard } from "src/app/common/services/auth.service";
import { AdminService } from "src/app/modules/admin/services/admin.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent implements OnInit {
  model: Enterprise = new Enterprise();
  userTypes = userTypesEnum; 

  authguard: any;
  constructor(
    private _adminService: AdminService,
    private _authguard: AuthGuard
  ) {
    this.authguard = _authguard;
  }

  ngOnInit() {
    // this.getEnterprise(17);
    
  }
  getEnterprise(id: number) {
    this._adminService.getEnterprise(id).subscribe(res => {
      this.model = <Enterprise>res;
    });
  }

  download() {
    this._adminService.downloadBulkFile().subscribe(res => {
      const filePath = <any>res;
      const a = document.getElementById("btnDownload");
      a.setAttribute("href", `${environment.apiUrl}/${filePath}`);
      a.setAttribute("target", "_blank");
      a.setAttribute("download", "test");
      a.click();
    });
  }

}
