<div class="row" *ngIf="!isReportDownloaded">
  <div class="col-sm-4">
  </div>
  <div class="col-sm-4">
    <div class="download-report">
      <div *ngIf="finalReportDownloadResponse.isOtpRequired">
        <form #downloadReportForm="ngForm">
          <div>
            <div>
              <mat-form-field appearance="outline">
                <mat-label>OTP</mat-label><input appCustomValidator required custommin="6" custommax="6" matInput
                  [(ngModel)]="downloadReportRequest.otp" placeholder="OTP*" name="otp" />
              </mat-form-field>
            </div>
            <div class="text-right">
              <button type="submit" [disabled]="!downloadReportForm.valid" (click)="validateOtpGetReport()"
                title="Assign" mat-raised-button color="primary">
                Verify OTP
              </button>
            </div>
          </div>
        </form>
      </div>
      <div *ngIf="!finalReportDownloadResponse.isOtpRequired">
        <form #downloadReportForm="ngForm">
          <div>
            <div>
              <mat-form-field appearance="outline">
                <mat-label>Email</mat-label><input appCustomValidator required custommin="1" custommax="50" type="email"
                  matInput [(ngModel)]="model.downloadedByEmailId" name="email" placeholder="Email*" />
              </mat-form-field>
            </div>
            <div class="text-right">
              <button type="submit" [disabled]="!downloadReportForm.valid" (click)="getReportOrSendOtp()" title="Assign"
                mat-raised-button color="primary">
                Verify Email
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

  </div>
  <div class="col-sm-4">
  </div>
</div>

<div class="row" *ngIf="isReportDownloaded">
  <div class="col-sm-3"></div>
  <div class="col-sm-6">
   <div class="download-message">
     Report downloaded successfully!
   </div>
  </div>
  <div class="col-sm-3"></div>
 </div>