<mat-card class="example-card" style="padding:0" id="dashboard-css">
  <mat-card-header style="background-color:none !important">
    <strong class="page-header-text">Dashboard</strong>
    <span class="spacer-between"></span>
    <a target="_blank" href="/API/ProfileUploads/BulkImport.xlsx" *ngIf="
        authguard.userTypeId == userTypes.SUPER_ADMIN ||
        authguard.userTypeId == userTypes.INFOQUEST_USER
      ">
      <i class="fa fa-download"></i>&nbsp;Download Bulk file</a>
    <a (click)="download()" *ngIf="authguard.userTypeId == userTypes.ENTERPRISE_USER">
      <i class="fa fa-download"></i>&nbsp;Download Bulk file</a>
  </mat-card-header>
  <mat-divider class="mat-divider"></mat-divider>

  <mat-card-content class="main-page-content dashboard-content">
    <!-- <app-enterprise-prof-status></app-enterprise-prof-status>-->
    <app-enterprise-check-status></app-enterprise-check-status>

    <div class="col-sm-12 mt-4">
      <!-- <div class="col-sm-6">
        <div class="box box-info">
          <div class="box-header with-border">
            <h3 class="box-title">Upcoming Holidays</h3>
            <div class="box-tools pull-right">
            </div>
          </div>
          <div class="table-responsive mt-3">
            <div class="bg-white table no-margin">
              <table>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Date</th>

                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor=" let ent of holidaylist">
                    <td>{{ent.title}}</td>
                    <td>
                      {{ent.date|date:"dd/MM/yyyy"}}
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div> -->
    </div>
    <!-- <app-holiday-list></app-holiday-list> -->
    <!--<app-profile-checks
      [isDashboard]="true"
      [isEnterprise]="false"
      [lstProfileChecksConfigs]="model.lstProfileChecksConfigs"
    ></app-profile-checks>-->
  </mat-card-content>
</mat-card>