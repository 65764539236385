import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from 'src/app/app.component';
import { AuthGuard } from 'src/app/common/services/auth.service';
import { CommonService } from 'src/app/common/services/common.service';
import { CommonDataService } from 'src/app/common/services/commonData.service';
import { AdminService } from 'src/app/modules/admin/services/admin.service';
import { DownloadReportRequest, FinalReportDownloadRequest, FinalReportDownloadResponse } from '../../models/final-report-download';
import { ProfileInfoResponse } from '../../models/profile-info-response';

@Component({
  selector: 'app-download-report',
  templateUrl: './download-report.component.html',
  styleUrls: ['./download-report.component.scss']
})
export class DownloadReportComponent implements OnInit {
  isShowLogin: boolean = false;
  model: FinalReportDownloadRequest = new FinalReportDownloadRequest();
  finalReportDownloadResponse: FinalReportDownloadResponse = new FinalReportDownloadResponse();
  downloadReportRequest: DownloadReportRequest = new DownloadReportRequest();
  profileInfoModel: ProfileInfoResponse = new ProfileInfoResponse();
  constructor(
    private _commonDataService: CommonDataService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _commonService: CommonService,
    private _appComponent: AppComponent,
    private _authguard: AuthGuard,
    private _matDialog: MatDialog,
    private _toaster: ToastrService,
    private _adminService: AdminService,
  ) { }

  ngOnInit() {
    this.isShowLogin = false;
    this._appComponent.isLoginPage = true;
    this.model.reportUniqueId = this._route.snapshot.params.id;
  }

  validateUser() {
    this.isShowLogin = true;
  }

  validateOtpGetReport() {
    this.downloadReportRequest.reportUniqueId = this._route.snapshot.params.id;
    this._adminService.validateOtpGetReport(this.downloadReportRequest).subscribe((res: any) => {
      this._commonService.downLoadPdf(res, `${this.profileInfoModel.firstName}_${this.profileInfoModel.lastName}.pdf`);
    });
  }

  getProfileInfo() {
    this.downloadReportRequest.reportUniqueId = this._route.snapshot.params.id;
    this._adminService.getProfileInfo({ reportUniqueId: this._route.snapshot.params.id }).subscribe((res: any) => {
      this.profileInfoModel = <ProfileInfoResponse>res;
      this.validateOtpGetReport();
    });
  }
  afterLogin(event: any) {
    this.getProfileInfo();
  }
}
