<mat-card class="example-card" style="padding:0">
  <mat-card-content>
    <div class="row col-sm-12" style="background: #ffffff">
      <div class="col-sm-3"></div>
      <div class="col-sm-6">
        <div class="form-warning">
          <strong class="text-center">
            You have no access for this Page
          </strong>
        </div>
      </div>
      <div class="col-sm-3"></div>
    </div>
  </mat-card-content>
</mat-card>
