<div class="row">
  <div class="col-sm-3 " *ngFor="let st of totalStatusCounts">
    <div class="ica-card">
      <div class="ica-card-common {{st.class}}">
        <img class="img-insuff-card" src="../assets/svg/bg.svg" />
        <div class="ica-body">
          <div class="pull-left">
            <h4>{{st.name}}</h4>
            <h2>{{st.count}}</h2>
            <button mat-button [matMenuTriggerFor]="menu" class="no-padding">more info</button>
            <mat-menu #menu="matMenu">
              <button *ngFor="let status of st.statuses" mat-menu-item class="btn-status-nav">
                <span (click)="navigateToProfileScreen(status.id,st.type)">
                  {{status.text}}
                </span>
              </button>
            </mat-menu>
          </div>
          <div class="pull-right">
            <i class="{{st.icon}} fa-ica" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">

  <div class="col-sm-3">
    <div class="row">
      <div class="col-sm-6 h6 no-padding">Profiles by status</div>
      <div class="col-sm-6 no-padding">
        <app-iqdate-range-picker #mailDatePicker
          (dateRangeSelectedEvent)="getEnterpriseProfileStatusesListByDate($event)"></app-iqdate-range-picker>
      </div>
    </div>
    <div class="bg-white">
      <h6 class="text-center"></h6>
      <canvas baseChart [data]="pieChartData" style="width: 97%; height: 167px" [options]="pieChartOptions"
        [legend]="false" [labels]="pieChartLabels" [type]="pieChartType"></canvas>
    </div>
  </div>

  <div class="col-sm-3">
    <div class="row">
      <div class="col-sm-4 h6 no-padding">Profiles created</div>
      <div class="col-sm-8 no-padding">
        <app-iqdate-range-picker #profileDatePicker
          (dateRangeSelectedEvent)="getProfileCount($event)"></app-iqdate-range-picker>
      </div>
    </div>
    <app-line-chart [profileCountResponse]="profileCountResponse" [title]="''" [lineChartType]="'line'"
      [height]="'167px'"></app-line-chart>
  </div>

  <div class="col-sm-3">
    <div class="row">
      <div class="col-sm-4 h6  no-padding">Reports</div>
      <div class="col-sm-8 no-padding">
        <app-iqdate-range-picker #totalReportPicker
          (dateRangeSelectedEvent)="getReportTypesCount($event)"></app-iqdate-range-picker>
      </div>
    </div>
    <div class="bg-white">
      <h6 class="text-center"></h6>
      <app-pie-chart [data]="finalReportCountResponse" [title]="''" [type]="'doughnut'"
        [height]="'167px'"></app-pie-chart>
    </div>
  </div>

  <div class="col-sm-3">
    <div class="row">
      <div class="col-sm-4 h6 no-padding">Mails sent</div>
      <div class="col-sm-8 no-padding">
        <app-iqdate-range-picker #mailDatePicker
          (dateRangeSelectedEvent)="getMailsCount($event)"></app-iqdate-range-picker>
      </div>
    </div>
    <app-line-chart [height]="'167px'" [profileCountResponse]="mailsCountResponse" [title]="''"
      [lineChartType]="'bar'"></app-line-chart>
  </div>

</div>
<div class="row">
  <div class="col-sm-3">
    <div class="row">
      <div class="col-sm-5 h6 no-padding">Reminders Mails</div>
      <div class="col-sm-7 no-padding">
        <app-iqdate-range-picker #reminderDatePicker
          (dateRangeSelectedEvent)="getReminderCount($event)"></app-iqdate-range-picker>
      </div>
    </div>
    <app-line-chart [profileCountResponse]="reminderCountResponse" [title]="''" [height]="'167px'"
      [lineChartType]="'bar'"></app-line-chart>
  </div>
  <div class="col-sm-6">
    <h6 class="text-center">Recent Logins</h6>
    <table class="table bg-white ">
      <tbody>
        <tr *ngFor="let item of userLogs">
          <td>
            <img class="circle login-icon login-user-dashboard" src="../assets/images/user.png">
            {{ item.userName }}
          </td>
          <td>
            <span class="login-status is-login-inactive" *ngIf="item.isExpired">
              In Active
            </span>
            <span class="login-status is-login-active" *ngIf="!item.isExpired">
              Active
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-sm-3">
    <h6 class="text-center">Upcoming Holidays</h6>
    <table class="table bg-white ">
      <tbody>
        <tr *ngFor=" let ent of holidaylist">
          <td>{{ent.title}}</td>
          <td>
            {{ent.date|date:"dd-MMM-yyyy"}}
          </td>

        </tr>
      </tbody>
    </table>
  </div>
</div>

<!-- <div class="row" *ngIf="loggedInUserTypeId==userTypes.SUPER_ADMIN">
  <div class="col-sm-12">
    <div class="row">
      <div class="col-sm-3" *ngFor="let st of profileStatusWiseSumValue">
        <div class="info-box">
          <span class="info-box-icon dashboard-icon {{st.infoColor}}">
            <i class="{{st.icon}}"></i>
          </span>
          <div class="info-box-content dashboard-content-body">
            <span class="info-box-text">{{st.text}}</span>
            <span class="info-box-number">
              {{st.value}}
              <a href="#" class="small-box-footer" (click)="navigateToProfileScreen(st.statusId)">
                More info <i class="fa fa-arrow-circle-right"></i>
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>

  </div>
</div> -->

<!-- 
<div class="row">
  <div *ngIf="loggedInUserTypeId!=userTypes.ENTERPRISE_USER" class="col-md-6">
    <div class="box box-info">
      <div class="box-header with-border">
        <h3 class="box-title">Enteprise wise status count</h3>
        <div class="box-tools pull-right">
        </div>
      </div>
      <div class="box-body">
        <div class="table-responsive">
          <table class="table no-margin">
            <thead>
              <tr>
                <th>Enterprise</th>
                <th>Total</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let ent of profileStatusList">
                <td>{{ent.enterprise}}</td>
                <td>
                  {{
                  ent.profileInProgressByEmployee +
                  ent.profileSubmittedByEmployee +
                  ent.profileInProgress +
                  ent.profileReferbacktoemployee +
                  ent.profileReferbacktoHR +
                  ent.profileProcessInitiated +
                  ent.profileProcessApproved +
                  ent.profileProcessInProgress +
                  ent.profileProcessCancelled +
                  ent.profileProcessCompleted
                  }}
                </td>
                <td>
                  <a (click)="loadEntChart(ent)"><i class="fa fa-pie-chart" aria-hidden="true"></i></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="bg-white text-center">
      <br />
      <strong class="page-header-text">{{selectedEnterpriseName}}</strong>
      <br />
      <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels" [type]=hartType"
        [colors]="chartColors"></canvas>
    </div>
  </div>
  <div class="col-sm-6" *ngIf="lineChartData.length>0">
    <div class="bg-white text-center">
      <canvas baseChart [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions"
        [legend]="lineChartLegend" [type]="lineChartType" (chartClick)="chartClicked($event)"
        [options]="{bezierCurve: false}">
      </canvas>
    </div>
  </div>
</div> -->