import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from 'src/app/app.component';
import { CommonService } from 'src/app/common/services/common.service';
import { map } from 'rxjs/operators';
import { AdminService } from 'src/app/modules/admin/services/admin.service';
import { DownloadReportRequest, FinalReportDownloadRequest, FinalReportDownloadResponse } from '../../models/final-report-download';

@Component({
  selector: 'app-final-report-download',
  templateUrl: './final-report-download.component.html',
  styleUrls: ['./final-report-download.component.scss']
})
export class FinalReportDownload implements OnInit {
  model: FinalReportDownloadRequest = new FinalReportDownloadRequest();
  finalReportDownloadResponse: FinalReportDownloadResponse = new FinalReportDownloadResponse();
  finalresponse: FinalReportDownloadResponse = new FinalReportDownloadResponse();
  downloadReportRequest: DownloadReportRequest = new DownloadReportRequest();
  isReportDownloaded: boolean = false;
  constructor(
    private _adminService: AdminService,
    private _route: ActivatedRoute,
    private _toastr: ToastrService,
    private _commonService: CommonService,
    private _appComponent: AppComponent,
  ) { }

  ngOnInit() {
    this._appComponent.isLoginPage = true;
    // this._commonService.clearToken();
    this.model.reportUniqueId = this._route.snapshot.params.id;
  }

  getReportOrSendOtp() {
    this._adminService.getReportOrSendOtp(this.model).subscribe(res => {
      this.finalReportDownloadResponse = <FinalReportDownloadResponse>res;
      if (!this.finalReportDownloadResponse.isEmailExist) {
        this._toastr.error(this.finalReportDownloadResponse.message);
      }
      if (this.finalReportDownloadResponse.isEmailExist && !this.finalReportDownloadResponse.isOtpRequired) {
        this.validateOtpGetReport();
      }
    });
  }

  validateOtpGetReport() {
    this.downloadReportRequest.reportUniqueId = this._route.snapshot.params.id;
    this._adminService.validateOtpGetReport(this.downloadReportRequest).subscribe((res: any) => {
      this.isReportDownloaded = true;
      this._commonService.downLoadExcel(res, 'Report.pdf');
    });
  }
}
