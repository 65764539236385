import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AppComponent } from "src/app/app.component";
import {
  appGlobalConstants, userTypesEnum
} from "src/app/common/constants/common.constants";
import { ValidateOtpRequest, ValidateUserLoginRequest } from "src/app/common/models/admin.class";
import { AuthGuard } from "src/app/common/services/auth.service";
import { ValidateOtpComponent } from "src/app/modules/shared/validate-otp/validate-otp.component";
import { environment } from "src/environments/environment";
import { App_Urls } from "../../../../common/constants/appurls";
import { CommonService } from "../../../../common/services/common.service";
import { CommonDataService } from "../../../../common/services/commonData.service";
import { ForgotPasswordComponent } from "../forgot-password/forgot-password.component";
import { MasterModel } from "src/app/common/models/applicant.class";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  public lat;
  public lng;
  loginForm: UntypedFormGroup;
  authguard: any;
  loggedinUserTypeId: number;
  userTypes = userTypesEnum;
  public browserDetails: any;
  validateUserLoginModel: ValidateUserLoginRequest = new ValidateUserLoginRequest();
  validateOtpRequest: ValidateOtpRequest = new ValidateOtpRequest();
  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _commonDataService: CommonDataService,
    private _router: Router,
    private _commonService: CommonService,
    private _authguard: AuthGuard,
    private _appComponent: AppComponent,
    private _matDialog: MatDialog,
    private _toaster: ToastrService,
  ) {
    this.authguard = _authguard;
  }

  ngOnInit() {
    this.loginForm = this._formBuilder.group({
      userName: ["", Validators.compose([Validators.required])],
      password: ["", Validators.compose([Validators.required])]
    });
    this.getBrowserDetails();
  }
  onLoginSubmit() {
    this.validateUserLoginModel = this.loginForm.value;
    this.validateUserLoginModel.browserDetails = JSON.stringify(this.browserDetails);
    this._commonDataService
      .loginDataAsync(App_Urls.VALIDATE_USER_LOGIN, this.validateUserLoginModel)
      .subscribe(data => {
        this.loggedinUserTypeId = data["userTypeId"];
        if (data["isAuthenticated"] && data["userTypeId"] && (data["userTypeId"] != userTypesEnum.SUPER_ADMIN && data["userTypeId"] != userTypesEnum.ENTERPRISE_USER && data["userTypeId"] != userTypesEnum.PROFILE_USER)) {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position: any) => {
              if (position) {
                console.log("Latitude: " + position.coords.latitude +
                  "Longitude: " + position.coords.longitude);
                this.lat = position.coords.latitude;
                this.lng = position.coords.longitude;
                this.processLogin(data);
              }
            },
              (error: any) => {
                console.log(error)
                if (error.message.toLowerCase()
                  .includes("denied")) {
                  this._toaster.warning("Geolocation mandatory to login");
                }
                else {
                  this.processLogin(data);
                }
              });
          } else {
            console.log("Geolocation is not supported by this browser.")
            this.processLogin(data);
          }

        }
        else {
          this.mapLoginResponse(data);
        }
      });
  }

  processLogin(data: any) {

    const dialogRef = this._matDialog.open(ValidateOtpComponent, {
      width: "500px"
    });
    const sub = dialogRef.componentInstance.onChangeStatus.subscribe(result => {
      this.validateOtpRequest.uniqueId = data["uniqueId"];
      this.validateOtpRequest.otp = result.otp;
      this.browserDetails["GeoLocation"] = JSON.stringify({ "lat": this.lat, "lang": this.lng });
      this.validateOtpRequest.browserDetails = JSON.stringify(this.browserDetails);
      this._commonDataService
        .loginDataAsync(App_Urls.VALIDATE_OTP, this.validateOtpRequest)
        .subscribe(data => {
          if (data["isAuthenticated"]) {
            dialogRef.close();
            this.mapLoginResponse(data);
          } else {
            this._toaster.error("Invalid OTP");
          }
        });
    });
  }

  mapLoginResponse(data: any) {
    if (data["isAuthenticated"]) {
      this._commonService.setTokenGuid(data["tokenGuid"]);
      this._commonService.setLoginName(data["loginName"]);
      this._appComponent.loginName = data["loginName"];
      this._commonService.setEnterpriseId(data["enterpriseID"]);
      this._appComponent.bindMenuItems();
      this._commonService.setUserTypeId(data["userTypeId"]);

      if (data["groupEnterprises"]) {
        const lstGroupEnterprises = <MasterModel[]>data["groupEnterprises"];
        this._commonService.setGroupEnterprises(lstGroupEnterprises);
        this._appComponent.lstEnterprises = lstGroupEnterprises;
        if (lstGroupEnterprises.length > 0) {
          const groupEnterpriseId = lstGroupEnterprises[0].id;
          this._commonService.setGroupEnterpriseId(groupEnterpriseId);
          this._appComponent.groupEnterpriseId = groupEnterpriseId;
        }
      }
      if (
        parseInt(data["userTypeId"], 10) ===
        this.userTypes.ENTERPRISE_USER ||
        parseInt(data["userTypeId"], 10) === this.userTypes.PROFILE_USER
      ) {
        this._appComponent.logoUrl = `${environment.apiUrl}/${data["logoUrl"]
          }`;
        this._commonService.setLogoUrl(
          `${environment.apiUrl}/${data["logoUrl"]}`
        );
      } else {
        this._appComponent.logoUrl = appGlobalConstants.logoUrl;
        this._commonService.setLogoUrl(appGlobalConstants.logoUrl);
      }
      if (data["isProfileUser"]) {
        this._appComponent.isProfileUser = true;
        this._router.navigateByUrl(
          `/applicant/profile-form/${data["profileGuid"]}/personal`
        );
      } else {
        this._appComponent.isProfileUser = false;
        this._router.navigateByUrl("/dashboard");
      }

    }
  }

  getBrowserDetails() {
    var browserAgent = navigator.userAgent;
    var browserName = navigator.appName;
    var browserVersion = '' + parseFloat(navigator.appVersion);
    var browserMajorVersion = parseInt(navigator.appVersion, 10);
    var Offset, OffsetVersion, ix;

    // For Chrome 
    if ((OffsetVersion = browserAgent.indexOf("Chrome")) != -1) {
      browserName = "Chrome";
      browserVersion = browserAgent.substring(OffsetVersion + 7);
    }

    // For Microsoft internet explorer 
    else if ((OffsetVersion = browserAgent.indexOf("MSIE")) != -1) {
      browserName = "Microsoft Internet Explorer";
      browserVersion = browserAgent.substring(OffsetVersion + 5);
    }

    // For Firefox
    else if ((OffsetVersion = browserAgent.indexOf("Firefox")) != -1) {
      browserName = "Firefox";
    }

    // For Safari
    else if ((OffsetVersion = browserAgent.indexOf("Safari")) != -1) {
      browserName = "Safari";
      browserVersion = browserAgent.substring(OffsetVersion + 7);
      if ((OffsetVersion = browserAgent.indexOf("Version")) != -1)
        browserVersion = browserAgent.substring(OffsetVersion + 8);
    }

    // For other browser "name/version" is at the end of userAgent 
    else if ((Offset = browserAgent.lastIndexOf(' ') + 1) <
      (OffsetVersion = browserAgent.lastIndexOf('/'))) {
      browserName = browserAgent.substring(Offset, OffsetVersion);
      browserVersion = browserAgent.substring(OffsetVersion + 1);
      if (browserName.toLowerCase() == browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }

    if ((ix = browserVersion.indexOf(";")) != -1)
      browserVersion = browserVersion.substring(0, ix);
    if ((ix = browserVersion.indexOf(" ")) != -1)
      browserVersion = browserVersion.substring(0, ix);


    browserMajorVersion = parseInt('' + browserVersion, 10);
    if (isNaN(browserMajorVersion)) {
      browserVersion = '' + parseFloat(navigator.appVersion);
      browserMajorVersion = parseInt(navigator.appVersion, 10);
    }

    var OSName = "Unknown";
    var userAgent = window.navigator.userAgent
    if (userAgent.indexOf("Windows NT 10.0") != -1) OSName = "Windows 10";
    if (userAgent.indexOf("Windows NT 6.2") != -1) OSName = "Windows 8";
    if (userAgent.indexOf("Windows NT 6.1") != -1) OSName = "Windows 7";
    if (userAgent.indexOf("Windows NT 6.0") != -1) OSName = "Windows Vista";
    if (userAgent.indexOf("Windows NT 5.1") != -1) OSName = "Windows XP";
    if (userAgent.indexOf("Windows NT 5.0") != -1) OSName = "Windows 2000";
    if (userAgent.indexOf("Mac") != -1) OSName = "Mac/iOS";
    if (userAgent.indexOf("X11") != -1) OSName = "UNIX";
    if (userAgent.indexOf("Linux") != -1) OSName = "Linux";

    this._commonDataService.getIPAddress()
      .subscribe(
        (data: any) => {
          let browserDtls = { Browser: browserName + '-' + browserVersion, IPAddress: data.ip, OS: OSName };
          this.browserDetails = browserDtls;
        },
        (error: any) => { }
      )
  }

  forgotPasswordClick() {
    const dialogRef = this._matDialog.open(ForgotPasswordComponent, {
      width: "600px",
      data: {

      },
    });
  }

}
