<mat-card class="example-card" style="padding:0">
  <mat-card-content>
    <div class="row col-sm-12" style="background: #ffffff">
      <div class="col-sm-3"></div>
      <div class="col-sm-6">
        <div class="form-success">
          <button class="btn-download" (click)="validateUser()">Download Report</button>
          <div *ngIf="isShowLogin">
            <app-validate-user (afterLogin)="afterLogin($event)"></app-validate-user>
          </div>
        </div>
      </div>
      <div class="col-sm-3"></div>
    </div>
  </mat-card-content>
</mat-card>