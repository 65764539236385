import { Routes } from "@angular/router";
import { AuthGuard } from "./common/services/auth.service";
import { DashboardComponent } from "./modules/common/components/dashboard/dashboard.component";
import { DownloadReportComponent } from "./modules/common/components/download-report/download-report.component";
import { FinalReportDownload } from "./modules/common/components/final-report-download/final-report-download.component";
import { LoginComponent } from "./modules/common/components/login/login.component";
import { ResetPasswordComponent } from "./modules/common/components/reset-password/reset-password.component";
import { UnauthorizedComponent } from "./modules/common/components/unauthorized/unauthorized.component";

export const App_Routes: Routes = [
  { path: "login", component: LoginComponent },
  { path: "reset-password/:id", component: ResetPasswordComponent },
  { path: "download-latest-report/:id", component: FinalReportDownload },
  { path: "download-report/:id", component: DownloadReportComponent },

  { path: "", component: DashboardComponent },
  { path: "instantchecks",
    loadChildren: () => import("./modules/instant-checks/instant-checks.module").then(module => module.InstantChecksModule)},
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: { pagePriv: "dashboard", module: "" },
  },

  {
    path: "applicant",
    loadChildren: () => import("./modules/applicant/applicant.module").then(module => module.ApplicantModule)
  },
  { path: "clarification", 
    loadChildren: () => import("./modules/checks/clarification/clarification.module").then(module => module.ClarificationModule)
    },
  { path: "user", 
    loadChildren: () => import("./modules/user/user.module").then(module => module.UserModule)
    },
  { path: "admin", 
    loadChildren: () => import("./modules/admin/admin.module").then(module => module.AdminModule)
    },
  { path: "check", 
    loadChildren: () => import("./modules/checks/checks.module").then(module => module.ChecksModule)
    },
  {
    path: "report",
    loadChildren: () => import("./modules/report/report.module").then(module => module.ReportModule)
  },
  { path: "public", 
    loadChildren: () => import("./modules/public/public.module").then(module => module.PublicModule)
    },
    { path: "profilecheck", 
      loadChildren: () => import("./modules/profile-info-check/profile-info-check.module").then(module => module.ProfileInfoCheckModule)
      },
  { path: "unauthorized", component: UnauthorizedComponent },
];
// D:\vsts\iq\bgv\InfoQuest.UI\InfoQuest.UI\src\app\modules\public\public.module.ts
