import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from 'src/app/app.component';
import { App_Urls } from 'src/app/common/constants/appurls';
import { CommonService } from 'src/app/common/services/common.service';
import { CommonDataService } from 'src/app/common/services/commonData.service';
import { ResetPassword } from '../../models/forgot-password.model';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  model: ResetPassword = new ResetPassword();
  constructor(
    private _toaster: ToastrService,
    private _commonDataService: CommonDataService,
    private _commonService: CommonService,
    private _appComponent: AppComponent,
    private _route: ActivatedRoute,
    public _router: Router
  ) { }

  ngOnInit() {
    this.model.resetToken=this._route.snapshot.params.id;
    this._appComponent.isLoginPage = true;
    this._commonService.clearToken();
  }

  onConfirmClick() {
    this._commonDataService
      .resetpassword(App_Urls.RESET_PASSWORD, this.model).subscribe(res => {
        this._toaster.success(res['message']);
        this._router.navigate(["/login"]);
      });
  }
}
