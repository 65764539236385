<h5 class="page-header-text text-center">Forgot Password</h5>
<hr />
<form #form="ngForm">
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input appCustomValidator [(ngModel)]="model.email" name="email" matInput type="email" custommin="1"
        custommax="50" placeholder="Email" required />
    </mat-form-field>
    <div class="form-div">
      <div class="col-md-12 text-right">
        <button type="button" class="md-default" mat-raised-button color="default" title="Cancel" (click)="onNoClick()">
          Cancel
        </button>
        <button type="submit" mat-raised-button color="primary" title="Save" [disabled]="!form.valid" (click)="onSaveClick()">
          Send Email
        </button>
      </div>
    </div>
  </div>