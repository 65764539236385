import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from 'src/app/app.component';
import { App_Urls } from 'src/app/common/constants/appurls';
import { userTypesEnum } from 'src/app/common/constants/common.constants';
import { ValidateUserLoginRequest } from 'src/app/common/models/admin.class';
import { AuthGuard } from 'src/app/common/services/auth.service';
import { CommonService } from 'src/app/common/services/common.service';
import { CommonDataService } from 'src/app/common/services/commonData.service';

@Component({
  selector: 'app-validate-user',
  templateUrl: './validate-user.component.html',
  styleUrls: ['./validate-user.component.scss']
})
export class ValidateUserComponent implements OnInit {
  @Output()
  afterLogin: EventEmitter<any> = new EventEmitter<any>();
  loginForm: UntypedFormGroup;
  userTypes = userTypesEnum;
  validateUserLoginModel: ValidateUserLoginRequest = new ValidateUserLoginRequest();
  constructor(
    // private dialogRef: MatDialogRef<ValidateUserComponent>,
    // @Inject(MAT_DIALOG_DATA) public model: any,
    private _formBuilder: UntypedFormBuilder,
    private _commonDataService: CommonDataService,
    private _router: Router,
    private _commonService: CommonService,
    private _appComponent: AppComponent,
    private _authguard: AuthGuard,
    private _matDialog: MatDialog,
    private _toaster: ToastrService,
  ) { }

  ngOnInit() {
    this.loginForm = this._formBuilder.group({
      userName: ["", Validators.compose([Validators.required])],
      password: ["", Validators.compose([Validators.required])]
    });

  }

  onLoginSubmit() {
    this.validateUserLoginModel = this.loginForm.value;
    this._commonDataService
      .loginDataAsync(App_Urls.VALIDATE_USER_LOGIN, this.validateUserLoginModel)
      .subscribe(data => {
        if (data["isAuthenticated"]) {
          this._commonService.setTokenGuid(data["tokenGuid"]);
          this.afterLogin.emit("login-success");
        }
      });
  }

}
