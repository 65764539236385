<div id="dv-login" class="dv-login">
  <div class="row col-sm-12">
    <div class="col-sm-4"></div>
    <div class="row col-sm-8 login-form">
      <div class="col-sm-6 login-fields">
        <div class="text-center">
          <img src="../assets/images/128-x-128.png">
        </div>
        <div class="text-center">
          <label style="font-weight: bold;margin-top: 12px;">Log in to Info Quest</label>
        </div>
        <form [formGroup]="loginForm" (ngSubmit)="onLoginSubmit()" class="loginForm">
          <mat-form-field>
            <input matInput formControlName="userName" placeholder="User Name*">
          </mat-form-field>
          <br />
          <mat-form-field>
            <input matInput formControlName="password" placeholder="Password*" type="password">
          </mat-form-field>
          <div class="margin-50">
            <button class="btn-login" type="submit" title="Login" mat-raised-button color="primary">Login</button>
          </div>
          <div class="text-right" *ngIf="loggedinUserTypeId != userTypes.PROFILE_USER">
            <a class="a-link" title="Forgot Password" (click)="forgotPasswordClick()">Forgot Password</a>
          </div>

        </form>
      </div>
    </div>

    <div class="col-sm-2"></div>
  </div>
</div>