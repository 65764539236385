export class FinalReportDownloadResponse {
    reportPath: string;
    message: string;
    isEmailExist: boolean;
    isOtpRequired: boolean;
}

export class FinalReportDownloadRequest {
    downloadedByEmailId: string;
    reportUniqueId: number;
}

export class DownloadReportRequest {
    otp: string;
    reportUniqueId: string;
}