import {
  ApplicationRef,
  CUSTOM_ELEMENTS_SCHEMA, NgModule
} from "@angular/core";

import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTableModule } from "@angular/material/table";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { BaseChartDirective, provideCharts, withDefaultRegisterables } from "ng2-charts";
import { ToastrModule } from "ngx-toastr";
import { CommonMaterialModule } from "./app.material.module";
// Services
import { CommonService } from "./common/services/common.service";
// App Routes
import { AppComponent } from "./app.component";
import { App_Routes } from "./app.routes";
import { ChecksMinInfoComponent } from "./modules/checks/checks/checks-min-info/checks-min-info.component";
import { EnterpriseCheckStatusComponent } from "./modules/checks/checks/enterprise-check-status/enterprise-check-status.component";
import { DashboardComponent } from "./modules/common/components/dashboard/dashboard.component";
import { DownloadReportComponent } from './modules/common/components/download-report/download-report.component';
import { FinalReportDownload } from "./modules/common/components/final-report-download/final-report-download.component";
import { ForgotPasswordComponent } from './modules/common/components/forgot-password/forgot-password.component';
import { LoginComponent } from "./modules/common/components/login/login.component";
import { ResetPasswordComponent } from './modules/common/components/reset-password/reset-password.component';
import { UnauthorizedComponent } from "./modules/common/components/unauthorized/unauthorized.component";
import { ValidateUserComponent } from "./modules/common/components/validate-user/validate-user.component";
import { NavMenuComponent } from "./modules/shared/nav-menu/nav-menu.component";
import { SharedModule } from "./modules/shared/shared.module";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavMenuComponent,
    DashboardComponent,
    EnterpriseCheckStatusComponent,
    ChecksMinInfoComponent,
    UnauthorizedComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    FinalReportDownload,
    DownloadReportComponent,
    ValidateUserComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    RouterModule.forRoot(App_Routes, { useHash: true }),
    MatTableModule,
    NgxDaterangepickerMd.forRoot(),
    SharedModule.forRoot(),
    MatTableModule,
    BaseChartDirective,
  ],

  providers: [
    CommonService,
      provideHttpClient(withInterceptorsFromDi()),
      provideCharts(withDefaultRegisterables())
  ]
})
export class AppModule {
  constructor(private _appRef: ApplicationRef) { }
  ngDoBootstrap() {
    this._appRef.bootstrap(AppComponent);
  }
}
