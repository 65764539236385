import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { App_Urls } from 'src/app/common/constants/appurls';
import { CommonDataService } from 'src/app/common/services/commonData.service';
import { ForgotPassword } from '../../models/forgot-password.model';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  model: ForgotPassword = new ForgotPassword();
  constructor(
    private _toaster: ToastrService,
    private _commonDataService: CommonDataService,
    private dialogRef: MatDialogRef<ForgotPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.dialogRef.disableClose = true;
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onSaveClick() {
    this._commonDataService
      .forgotPassword(App_Urls.FORGOT_PASSWORD, this.model).subscribe(res => {
        this._toaster.success('Reset password link has been sent to your email.');
        this.dialogRef.close({ isSaveSuccess: true });
      });
  }

}
