import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ChartData, ChartOptions } from 'chart.js';
import { userTypesEnum } from "src/app/common/constants/common.constants";
import { EnterpriseProfileStatusModel, Holiday, UserSessionList } from "src/app/common/models/admin.class";
import { MasterModel } from "src/app/common/models/applicant.class";
import { AmplifyService } from "src/app/common/services/amplify.service";
import { CommonService } from "src/app/common/services/common.service";
import { SharedService } from "src/app/modules/shared/services/shared.service";
import { AdminService } from "../../../admin/services/admin.service";
import { ClarificationService } from "src/app/modules/applicant/services/clarification.service";
import { ChartCountRequest, IFinalreportTypeCount, IInsuffDashboardResponse, IProfileCountResponse } from "../../clarification/hr-confirm.models";
import { HRConfirmTypesEnum, HRConfirmTypesLabelEnum } from 'src/app/common/constants/workflow.statustypes';
import moment from "moment";
@Component({
  selector: "app-enterprise-check-status",
  templateUrl: "./enterprise-check-status.component.html",
  styleUrls: ["./enterprise-check-status.component.scss"]
})
export class EnterpriseCheckStatusComponent implements OnInit {
  userTypes = userTypesEnum;
  lstEnterPrises: MasterModel[];
  pageSize: number;
  userLogs: UserSessionList[] = [];
  authguard: any;
  totalRecords: number;
  searchFilter: EnterpriseProfileStatusModel = new EnterpriseProfileStatusModel();
  pageSizeOptions: number[];
  profileStatusList: [];
  profileStatusWiseSumValue = [];
  totalStatusCounts = [];
  selectedEnterpriseName: string;
  profileEnterpriseDateWise: any[];
  profileCountResponse: IProfileCountResponse[] = [];
  mailsCountResponse: IProfileCountResponse[] = [];
  reminderCountResponse: IProfileCountResponse[] = [];
  finalReportCountResponse: IFinalreportTypeCount[] = [];
  holidaylist: Array<Holiday>;
  loggedInUserTypeId: number;
  public pieChartLabels = [];
  public pieChartData: ChartData<'doughnut'> = {
    labels: this.pieChartLabels,

    datasets: [
      {
        data: [],
        backgroundColor: ["orange", "#409f40", "darkorange", "#dc3545", "brown", "gray", "#2f8e68", "#007bff", "#dc3545", "#409f40", "skyblue"]
      }
    ],
  };
  public pieChartType = 'doughnut';
  public pieChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false
  };

  public chartColors: any[] = [
    {
      backgroundColor: ["orange", "#409f40", "darkorange", "#dc3545", "brown", "gray", "#2f8e68", "#007bff", "#dc3545", "#409f40", "skyblue"]
    }];

  public lineChartData: any[] = [

    //{ data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A', fill: false, showLine: true },
  ];
  public lineChartLabels: any[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];


  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];

  initialCounts = {
    profileInProgress: 0, profileInProgressByEmployee: 0, profileProcessApproved: 0,
    profileProcessCancelled: 0, profileProcessCompleted: 0, profileProcessInProgress: 0,
    profileProcessInitiated: 0, profileReferbacktoHR: 0, profileReferbacktoemployee: 0,
    profileSubmittedByEmployee: 0
  };

  constructor(
    private _adminService: AdminService,
    private _commonService: CommonService,
    private _amplifyService: AmplifyService,
    private _sharedService: SharedService,
    private _clarificationService: ClarificationService
  ) {
  }
  displayedColumns: string[] = [
    "enterprise",
    "nonVendor",
    "caseReInitiate",
    "closedbyVendor",
    "closed",
    "requestforSiteCheck",
    "requestforDD",
    "assigntoVendor",
    "amountApproval",
    "internalUpdate",
    "clarification",
    "insuffCleared",
    "insuffRaised",
    "universityGenunityCheck",
    "nottobeInitiated",
    "followupbyEmail",
    "followupbyphone",
    "rOCCheck",
    "caseInProgress",
    "caseInitiated",

    "total"
  ];
  dataSource = new MatTableDataSource(<EnterpriseProfileStatusModel[]>[]);

  @ViewChild(MatPaginator, { static: false })
  paginator: MatPaginator;

  ngOnInit() {
    this.totalStatusCounts = [];
    this.selectedEnterpriseName = "";
    const storedData = this._amplifyService.GetStorage(
      "enterprise_check_status_list"
    );
    if (storedData !== undefined && storedData != null) {
      this.searchFilter = <EnterpriseProfileStatusModel>storedData;
    }
    this._amplifyService.ClearStorage("enterprise_check_status_list");
    this.dataSource.paginator = this.paginator;


    switch (+this._commonService.getUserTypeId()) {
      case userTypesEnum.SUPER_ADMIN:
        this.initialData();
        break;
      case userTypesEnum.ENTERPRISE_USER:
        if (parseInt(this._commonService.getEnterpriseId(), 10) > 0) {
          this.searchFilter.assignedEnterprises = [parseInt(this._commonService.getEnterpriseId(), 10)];
        }
        this.initialData();
        break;
      case userTypesEnum.PROFILE_USER:

        break;

      default:
        this.getEnterprises();
        break;
    }

  }

  initialData() {
    this.loggedInUserTypeId = parseInt(this._commonService.getUserTypeId());
    this.defaultSearhFilter();
    this.pageSizeOptions = this._commonService.pageSizeOptions;
    this.getEnterpriseProfileStatusesListFromInception();
    this.getEnterpriseDateWiseReport(parseInt(this._commonService.getEnterpriseId(), 10));
    this.getUserSessionList();
    this.getHolidayList();
  }

  getEnterpriseDateWiseReport(enterpriseId: number) {
    this.lineChartData = [];
    const searchModel = { ...this.searchFilter, enterpriseId: enterpriseId };
    this._adminService
      .GetEnterpriseDateWiseReport(searchModel)
      .subscribe(res => {
        if (res) {
          this.profileEnterpriseDateWise = <[]>res;

          var entLevelDetails = [];
          this.profileEnterpriseDateWise.forEach(element => {
            var date = new Date(element.date);
            if (new Date().getUTCFullYear() - 1 == date.getUTCFullYear()) {
              if (entLevelDetails.length > 0) {
                var curEnt = entLevelDetails.find(x => x.entName == element.enterpriseName && x.month == date.getUTCMonth());
                if (curEnt == null) {
                  entLevelDetails.push({ entName: element.enterpriseName, month: date.getUTCMonth(), count: 1 });
                }
                else {
                  curEnt.count = curEnt.count + 1;
                }
              }
              else {
                entLevelDetails.push({ entName: element.enterpriseName, month: date.getUTCMonth(), count: 1 });
              }
            }
          });
          var entList = entLevelDetails.map(function (a) { return a.entName; });
          var completedList = [];
          entList.forEach(ent => {
            if (completedList.find(x => x == ent) == null) {
              completedList.push(ent);
              var perEnt = entLevelDetails.filter(x => x.entName == ent);
              var groupedObject = { data: [], label: ent, fill: false, showLine: true };
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].forEach(i => {
                var monthReport = perEnt.find(x => x.month == i);
                if (monthReport != null) {
                  groupedObject.data.push(monthReport.count);
                }
                else {
                  groupedObject.data.push(0);
                }
              });
              this.lineChartData.push(groupedObject);
            }
          });
        }
      });
  }

  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  getEnterpriseProfileStatusesListByDate(dateRange) {
    if (dateRange.startDate && dateRange.endDate) {
      this.searchFilter.fromDate = dateRange.startDate.format('YYYY-MMM-DD');
      this.searchFilter.toDate = dateRange.endDate.format('YYYY-MMM-DD');
    }
    this.getEnterpriseProfileStatusesList();
  }


  GetEnterpriseCheckStatusesList() {
    this._adminService
      .GetEnterpriseCheckStatuses(this.searchFilter)
      .subscribe(res => {
        this.totalRecords = res["item1"];
        this.dataSource = new MatTableDataSource(
          <EnterpriseProfileStatusModel[]>res["item2"]
        );
      });
  }

  getEnterprises() {
    this._sharedService.getEnterPrises().subscribe((res) => {
      this.lstEnterPrises = <MasterModel[]>res;
      this.searchFilter.assignedEnterprises = [];
      this.lstEnterPrises.forEach(o => {
        this.searchFilter.assignedEnterprises.push(o.id);
      })
      this.initialData();
    });
  }

  getEnterpriseProfileStatusesListFromInception() {
    this._adminService
      .getEnterpriseProfileStatuses(this.searchFilter)
      .subscribe(res => {
        this.profileStatusList = res["item2"];
        this.calculateProfileStatusCounts(true)
        this.getBannerCounts();
        this.getICACounts();
      });
  }

  getEnterpriseProfileStatusesList() {
    this._adminService
      .getEnterpriseProfileStatuses(this.searchFilter)
      .subscribe(res => {
        this.profileStatusList = res["item2"];
        this.calculateProfileStatusCounts(false)
        this.applyStatusChart();
      });
  }

  calculateProfileStatusCounts(isInitialLoad: boolean) {
    const revisedCounts = {
      profileInProgress: 0, profileInProgressByEmployee: 0, profileProcessApproved: 0,
      profileProcessCancelled: 0, profileProcessCompleted: 0, profileProcessInProgress: 0,
      profileProcessInitiated: 0, profileReferbacktoHR: 0, profileReferbacktoemployee: 0,
      profileSubmittedByEmployee: 0
    };

    this.profileStatusList.forEach(element => {
      if (isInitialLoad) {
        Object.keys(this.initialCounts).forEach(key => {
          this.initialCounts[key] += parseInt(element[key]) || 0;
        });
      }
      else {
        Object.keys(revisedCounts).forEach(key => {
          revisedCounts[key] += parseInt(element[key]) || 0;
        });
      }
    });

    this.profileStatusWiseSumValue = [
      { statusId: 1, value: !isInitialLoad ? revisedCounts.profileInProgressByEmployee : this.initialCounts.profileInProgressByEmployee, text: "Profile In Progress By Employee", icon: "fa fa-file-text", infoColor: "bg-orange", group: 'client' },
      { statusId: 2, value: !isInitialLoad ? revisedCounts.profileSubmittedByEmployee : this.initialCounts.profileSubmittedByEmployee, text: "Submitted By Employee", icon: "fa fa-paper-plane", infoColor: "bg-lightgreen", group: 'client' },
      { statusId: 3, value: !isInitialLoad ? revisedCounts.profileInProgress : this.initialCounts.profileInProgress, text: "Profile In Progress", icon: "fa fa-spinner", infoColor: "bg-darkorange", group: 'iq' },
      { statusId: 4, value: !isInitialLoad ? revisedCounts.profileReferbacktoemployee : this.initialCounts.profileReferbacktoemployee, text: "Referback to Employee", icon: "fa fa-fast-backward", infoColor: "bg-danger", group: 'client' },
      { statusId: 5, value: !isInitialLoad ? revisedCounts.profileReferbacktoHR : this.initialCounts.profileReferbacktoHR, text: "Referback to HR", icon: "fa fa-step-backward", infoColor: "bg-brown", group: 'client' },
      { statusId: 6, value: !isInitialLoad ? revisedCounts.profileProcessInitiated : this.initialCounts.profileProcessInitiated, text: "Process Initiated", icon: "fa fa-shopping-basket", infoColor: "bg-secondary", group: 'iq' },
      { statusId: 7, value: !isInitialLoad ? revisedCounts.profileProcessApproved : this.initialCounts.profileProcessApproved, text: "Profile Approved", icon: "fa fa-thumbs-up", infoColor: "bg-approved", group: 'iq' },
      { statusId: 8, value: !isInitialLoad ? revisedCounts.profileProcessInProgress : this.initialCounts.profileProcessInProgress, text: "Profile Process In Progress", icon: "fa fa-circle-o-notch", infoColor: "bg-primary", group: 'iq' },
      { statusId: 9, value: !isInitialLoad ? revisedCounts.profileProcessCancelled : this.initialCounts.profileProcessCancelled, text: "Profile Cancelled", icon: "fa fa-window-close", infoColor: "bg-danger", group: 'iq' },
      { statusId: 10, value: !isInitialLoad ? revisedCounts.profileProcessCompleted : this.initialCounts.profileProcessCompleted, text: "Profile Completed", icon: "fa fa-check", infoColor: "bg-success", group: 'iq' }
    ];

  }

  applyStatusChart() {
    this.pieChartLabels = [];
    this.pieChartData.labels = [];
    this.pieChartData.datasets.at(0).data = [];
    for (let item of this.profileStatusWiseSumValue) {
      this.pieChartData.labels.push(item.text);
      this.pieChartData.datasets.at(0).data.push(item.value);
    }
  }

  navigateToProfileScreen(statusId, type) {
    switch (type) {
      case 'peofilelist':
        window.open(window.location.origin + "/#/applicant/profilelist/" + statusId);
        break;
      default:
        window.open(window.location.origin + "/#/clarification/hrconfirm/" + type);
        break;
    }
  }

  loadEntChart(ent: any) {
    this.getEnterpriseDateWiseReport(ent.enterpriseId);
    this.selectedEnterpriseName = ent.enterprise;
    var labelData = [ent.profileInProgressByEmployee,
    ent.profileSubmittedByEmployee,
    ent.profileInProgress,
    ent.profileReferbacktoemployee,
    ent.profileReferbacktoHR,
    ent.profileProcessInitiated,
    ent.profileProcessApproved,
    ent.profileProcessInProgress,
    ent.profileProcessCancelled,
    ent.profileProcessCompleted
    ];
    var labels = ["Profile In Progress By Employee - " + ent.profileInProgressByEmployee, "Submitted By Employee - " + ent.profileSubmittedByEmployee,
    "Profile In Progress - " + ent.profileInProgress, "Referback to Employee - " + ent.profileReferbacktoemployee, "Referback to HR - " + ent.profileReferbacktoHR,
    "Process Initiated - " + ent.profileProcessInitiated, "Profile Approved - " + ent.profileProcessApproved, "Profile Process In Progress - " + ent.profileProcessInProgress,
    "Profile Cancelled - " + ent.profileProcessCancelled, "Profile Completed - " + ent.profileProcessCompleted];

    this.pieChartLabels = labels;
    this.pieChartData.datasets.at(0).data = labelData;
  }

  onPageClick(event: any) {
    this.searchFilter.pageNo = event.pageIndex;
    this.searchFilter.pageSize = event.pageSize;
    this.GetEnterpriseCheckStatusesList();
  }

  onSearchClick() {
    this.GetEnterpriseCheckStatusesList();
  }

  onResetClick() {
    this._amplifyService.ClearStorage("enterprise_check_status_list");
    this.searchFilter = new EnterpriseProfileStatusModel();
    this.defaultSearhFilter();
    this.GetEnterpriseCheckStatusesList();
  }
  defaultSearhFilter() {
    this.searchFilter.pageNo = this._commonService.pageNo;
    this.searchFilter.pageSize = this._commonService.pageSize;
    const entpriseId = parseInt(this._commonService.getEnterpriseId(), 10);
    if (entpriseId != null && !isNaN(entpriseId)) {
      this.searchFilter.enterpriseId = entpriseId;
    }
  }

  getICACounts(): void {
    this._clarificationService.getICACounts().subscribe((res: IInsuffDashboardResponse) => {
      if (!this.totalStatusCounts) {
        this.totalStatusCounts = [];
      }
      const testapp = HRConfirmTypesLabelEnum.Approvals;
      this.totalStatusCounts = this.totalStatusCounts.concat([
        { icon: 'fa fa-exclamation-circle', name: 'Clarifications', class: 'clarification-card', count: res.clarificationCount, statuses: [{ id: 4, text: 'Clarifications' }], type: HRConfirmTypesLabelEnum.Clarifications },
        { icon: 'fa fa-thumbs-up', name: 'Approvals', class: 'approval-card', count: res.approvalCount, statuses: [{ id: 2, text: 'Approvals' }], type: HRConfirmTypesLabelEnum.Approvals },
        { icon: 'fa fa-fast-backward', name: 'Insufficiency', class: 'insuff-card', count: res.insuffCount, statuses: [{ id: 3, text: 'Insufficiency' }], type: HRConfirmTypesLabelEnum.Insuff },
      ]);
    });
  }

  getBannerCounts(): void {
    this.totalStatusCounts = ([
      { icon: 'fa fa-check', name: 'Completed', class: 'completed-card', count: this.initialCounts.profileProcessCompleted, statuses: [{ id: 10, text: 'Profile Completed' }], type: 'peofilelist' },
      {
        icon: 'fa fa-spinner', name: 'Inprogress', class: 'inprogress-card', count: this.initialCounts.profileProcessInitiated + this.initialCounts.profileProcessApproved + this.initialCounts.profileProcessInProgress,
        statuses: [{ id: 6, text: 'Process Initiated' }, { id: 7, text: 'Profile Approved' }, { id: 8, text: 'Profile Process In Progress' }], type: 'peofilelist'
      },
      {
        icon: 'fa fa-history', name: 'HR Action', class: 'pending-card', count: this.initialCounts.profileSubmittedByEmployee + this.initialCounts.profileInProgress + this.initialCounts.profileReferbacktoHR,
        statuses: [{ id: 2, text: 'Submitted By Employee' }, { id: 3, text: 'Profile In Progress' }, { id: 5, text: 'Referback to HR' }], type: 'peofilelist'
      },
      { icon: 'fa fa-times', name: 'Cancelled', class: 'cancelled-card', count: this.initialCounts.profileProcessCancelled, statuses: [{ id: 9, text: 'Profile Cancelled' }], type: 'peofilelist' },
      { icon: 'fa fa-history', name: 'Pending Candidate', class: 'pending-card', count: this.initialCounts.profileInProgressByEmployee + this.initialCounts.profileInProgress + this.initialCounts.profileReferbacktoemployee, statuses: [{ id: 1, text: 'Profile In Progress By Employee' }, { id: 4, text: 'Referback to Employee' }], type: 'peofilelist' },
    ]);
  }

  getProfileCount(dateRange): void {
    if (!dateRange.startDate || !dateRange.endDate) {
      return;
    }
    this._clarificationService.getProfileCount(new ChartCountRequest({
      fromDate: dateRange.startDate.format('YYYY-MMM-DD'),
      toDate: dateRange.endDate.format('YYYY-MMM-DD')
    })).subscribe((res: IProfileCountResponse[]) => {
      this.profileCountResponse = res;
    });
  }


  getMailsCount(dateRange): void {

    if (!dateRange.startDate || !dateRange.endDate) {
      return;
    }
    this._clarificationService.getMailsCount(new ChartCountRequest({
      fromDate: dateRange.startDate.format('YYYY-MMM-DD'),
      toDate: dateRange.endDate.format('YYYY-MMM-DD')
    })).subscribe((res: IProfileCountResponse[]) => {
      this.mailsCountResponse = res;
    });
  }

  getReminderCount(dateRange): void {     
    if (!dateRange.startDate || !dateRange.endDate) {
      return;
    }
    this._adminService.getReminderCount(new ChartCountRequest({
      fromDate: dateRange.startDate.format('YYYY-MMM-DD'),
      toDate: dateRange.endDate.format('YYYY-MMM-DD')
    })).subscribe((res: IProfileCountResponse[]) => {
      this.reminderCountResponse = res;
    });
  }

  getUserSessionList() {
    this._adminService.getUserSessionList({ pageSize: 5, pageNo: 0 }).subscribe(res => {
      // this.totalRecords = res["item1"];
      this.userLogs = <UserSessionList[]>res["item2"];
    });
  }
  getHolidayList() {
    const today = new Date();
    this._adminService.getHolidayList({ isDashboard: false, pageSize: 5, pageNo: 0 }).subscribe(res => {
      this.holidaylist = res["item2"];
      this.holidaylist = this.holidaylist.filter(item => {
        if (new Date(item.date) >= today) {
          return item;
        }
      });
    });
  }

  getReportTypesCount(dateRange): void {
    if (!dateRange.startDate || !dateRange.endDate) {
      return;
    }
    this._clarificationService.getReportTypesCount(new ChartCountRequest({
      fromDate: dateRange.startDate.format('YYYY-MMM-DD'),
      toDate: dateRange.endDate.format('YYYY-MMM-DD')
    })).subscribe((res: IFinalreportTypeCount[]) => {
      this.finalReportCountResponse = res;
    });
  }
}
