<div class="row">
  <div class="col-sm-4"></div>
  <div class="col-sm-4">
    <div class="reset-password-body">
      <h5 class="page-header-text text-center">Reset Password</h5>
      <hr />
      <form #resetPasswordForm="ngForm">
        <div>
          <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input appCustomValidator [(ngModel)]="model.password" name="password" required matInput custommin="4"
              custommax="20" placeholder="Password" type="password" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Confirm Password</mat-label>
            <input [(ngModel)]="model.confirmPassword" name="confirmPassword" validateEqual="password" required matInput
              custommin="4" custommax="20" placeholder="Confirm Password" type="password" />
          </mat-form-field>
          <span *ngIf="model.password != model.confirmPassword" class="error-dir-message"
            style="display: block">Password
            and
            confirm Password not matched</span>
        </div>
        <div class="text-center">
          <button type="submit" data-dismiss="modal" (click)="onConfirmClick()" title="Save" mat-raised-button
            color="primary" [disabled]="
          !resetPasswordForm.valid || model.password != model.confirmPassword
        ">
            Reset my Password
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="col-sm-4"></div>
</div>